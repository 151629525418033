import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Polis Opinion Matrix`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` `}{`*`}{` `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` = a `}<a parentName="li" {...{
          "href": "/sparse-matrix",
          "title": "sparse matrix"
        }}>{`sparse matrix`}</a>{` of `}<a parentName="li" {...{
          "href": "/votes",
          "title": "votes"
        }}>{`votes`}</a>{``}</li>
      <li parentName="ul">{`Consider an excel table`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` are columns, each column represents a statement submitted by a `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{``}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` are rows, each row is the voting record of a `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{`.`}</li>
          <li parentName="ul">{`Thus, the excel sheet fills with `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` by `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` on `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{`The resulting `}<a parentName="li" {...{
          "href": "/sparse-matrix",
          "title": "sparse matrix"
        }}>{`sparse matrix`}</a>{`, and metadata, are available in the `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{``}</li>
      <li parentName="ul">{`The matrix is said to be sparse because it will (very likely) not be complete — ie., most participants will not vote on most comments.`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Algorithms",
          "title": "👾 Algorithms"
        }}>{`👾 Algorithms`}</a>{` and `}<a parentName="li" {...{
          "href": "/Analysis",
          "title": "🔬 Analysis"
        }}>{`🔬 Analysis`}</a>{` are run on the matrix`}</li>
      <li parentName="ul">{`There is no `}<a parentName="li" {...{
          "href": "/natural-language-processing",
          "title": "natural language processing"
        }}>{`natural language processing`}</a>{` involved in the clustering`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      